<script setup lang="ts">
</script>

<template>
  <div class="min-h-screen">
    <slot />
  </div>
</template>

<style>
</style>
